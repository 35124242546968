import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Button, Typography, Grid, Card, CardContent, CardActions } from '@mui/material';
import { styled } from '@mui/system';
import { useAuth } from './AuthContext';
import { BUTTON_LABELS, PRIVILEGES } from './constants';

const StyledButton = styled(Button)({
  backgroundColor: '#f18944',
  color: '#000000',
  '&:hover': {
    backgroundColor: '#d9783a',
  },
  borderRadius: '50px',
  padding: '10px 20px',
  fontWeight: 'bold',
});

const RectangleButton = styled(Button)({
  backgroundColor: '#1F3F66',
  color: '#fff',
  padding: '10px 20px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#173150',
  },
  borderRadius: '5px',
});

const Home = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/docker-compose.yaml`; // Point to the correct file location
    link.setAttribute('download', 'docker-compose.yaml'); // Specify the file name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <Grid container spacing={4} sx={{ marginTop: 4, justifyContent: 'center', alignItems: 'flex-start' }}>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center', mb: 6, marginRight: 20 }}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={`${process.env.PUBLIC_URL}/graphic.png`} alt="Graphic" style={{ width: '120%', height: 'auto' }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 6, marginTop: 5 }}>
          <Card sx={{ height: '100%', width: '120%', marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h5" component="div"sx={{ textAlign: 'center' }}>
                1Gateway Core Software
              </Typography>
            </CardContent>
            <CardActions>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <StyledButton onClick={handleDownload}>
                  {BUTTON_LABELS.DOWNLOAD}
                </StyledButton>
              </Box>
            </CardActions>
          </Card>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around', marginTop: 7 }}>
            {user?.privilege === PRIVILEGES.SUPER ? (
              <>
                <RectangleButton onClick={() => navigate('/partners')} sx={{ marginRight: 5 }}>Partners</RectangleButton>
                <RectangleButton onClick={() => navigate('/customers')}>Customers</RectangleButton>
              </>
            ) : user?.privilege === PRIVILEGES.ADMIN ? (
              <>
                <RectangleButton onClick={() => navigate('/customers')} sx={{ marginRight: 5 }}>Customers</RectangleButton>
                <RectangleButton onClick={() => navigate('/plugins')}>Plugins</RectangleButton>
              </>
            ) : (
              <>
                <RectangleButton onClick={() => navigate('/plugins')} sx={{ marginRight: 5 }}>Plugins</RectangleButton>
                <RectangleButton onClick={() => navigate('/licenses')}>Licenses</RectangleButton>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
