import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import { useForm } from './hooks/useForm';
import './styles.css';
import { COLORS, BUTTON_LABELS } from './constants';

const AddPartnerForm = ({ open, onClose, onAddPartner }) => {
  const [formValues, handleChange, resetForm] = useForm({
    name: '',
    email: '',
    country: '',
    city: '',
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    setIsSubmitDisabled(!formValues.name);
  }, [formValues.name]);

  const handleSubmit = () => {
    const newPartner = {
      ...formValues,
      password: 'abc123', // Default password
    };
    onAddPartner(newPartner);
    onClose();
    resetForm();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add a New Partner</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="name"
          name="name"
          label="Name"
          fullWidth
          value={formValues.name}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          id="email"
          name="email"
          label="Email"
          type="email"
          fullWidth
          value={formValues.email}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="country"
          name="country"
          label="Country"
          fullWidth
          value={formValues.country}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="city"
          name="city"
          label="City"
          fullWidth
          value={formValues.city}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={resetForm}
          style={{ color: 'white', backgroundColor: COLORS.BLUE }}
        >
          {BUTTON_LABELS.RESET}
        </Button>
        <Button
          onClick={onClose}
          style={{ color: 'white', backgroundColor: COLORS.ORANGE }}
        >
          {BUTTON_LABELS.CANCEL}
        </Button>
        <Button
          onClick={handleSubmit}
          style={{
            color: 'white',
            backgroundColor: isSubmitDisabled ? COLORS.GREY : COLORS.BLUE,
          }}
          variant="contained"
          disabled={isSubmitDisabled}
        >
          {BUTTON_LABELS.SUBMIT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPartnerForm;
