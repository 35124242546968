import React from 'react';
import { TextField, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchInput = ({ searchTerm, setSearchTerm, sx }) => (
  <Grid item xs={12} sm={6} md={2.4} sx={sx}>
    <TextField
      variant="outlined"
      label="Search by name..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{ startAdornment: <SearchIcon /> }}
      fullWidth
    />
  </Grid>
);

export default SearchInput;