import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  Grid,
  Button,
  IconButton,
  Card,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import AddPartnerForm from './AddPartnerForm'; 
import { useGlobalState } from './GlobalState';
import SearchInput from './SearchInput';
import { useAuth } from './AuthContext';
import './styles.css';
import { API_ENDPOINTS, ERROR_MESSAGES, BUTTON_LABELS, COLORS } from './constants';
import {
  SET_PARTNERS,
  SET_CUSTOMERS,
  SET_SELECTED_PARTNER,
  CLEAR_SELECTED_PARTNER,
} from './actionTypes';

const OrangeButton = styled(Button)({
  backgroundColor: COLORS.ORANGE,
  '&:hover': { backgroundColor: '#d9783a' },
  '&.Mui-disabled': { backgroundColor: 'lightgrey' },
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  color: 'black',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
});

const Partners = () => {
  const { state, dispatch } = useGlobalState();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false); // Default to false
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPartner, setSelectedPartner] = useState(state.selectedPartner);
  const [openAddPartner, setOpenAddPartner] = useState(false);
  const [partnerCustomers, setPartnerCustomers] = useState([]);

  useEffect(() => {
    const fetchPartners = async () => {
      setLoading(true); // Start loading
      try {
        const partnerResponse = await axios.get(`https://pluginrepo.north.1bonding.com/partner/all`, {
          headers: {
            Authorization: 'Basic cGx1Z2luOjFCMG5kIW5n' 
          },
        });
        dispatch({ type: SET_PARTNERS, payload: partnerResponse.data });
        setLoading(false); // Stop loading
      } catch (err) {
        console.error(ERROR_MESSAGES.FETCH_DATA_FAILED, err);
        setError(ERROR_MESSAGES.FETCH_DATA_FAILED);
        setLoading(false); // Stop loading
      }
    };

    const fetchCustomers = async () => {
      try {
        const customerResponse = await axios.get(`https://pluginrepo.north.1bonding.com/customer/all`, {
          headers: {
            Authorization: 'Basic cGx1Z2luOjFCMG5kIW5n' 
          },
        });
        dispatch({ type: SET_CUSTOMERS, payload: customerResponse.data });
      } catch (err) {
        console.error(ERROR_MESSAGES.FETCH_DATA_FAILED, err);
        setError(ERROR_MESSAGES.FETCH_DATA_FAILED);
      }
    };

    if (state.partners.length === 0) {
      fetchPartners();
    } else {
      setLoading(false); // If partners already fetched, stop loading
    }

    if (state.customers.length === 0) {
      fetchCustomers();
    }

    return () => {
      dispatch({ type: CLEAR_SELECTED_PARTNER });
    };
  }, [dispatch, state.partners.length, state.customers.length]);

  const handleAddPartner = async (newPartner) => {
    try {
      const response = await axios.post(`https://pluginrepo.north.1bonding.com/partner`, newPartner, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic cGx1Z2luOjFCMG5kIW5n',
        },
      });
      dispatch({ type: SET_PARTNERS, payload: [...state.partners, response.data] });
    } catch (error) {
      console.error(ERROR_MESSAGES.ADD_PARTNER_FAILED, error);
      setError(ERROR_MESSAGES.ADD_PARTNER_FAILED);
    }
  };

  const handlePartnerClick = (partner) => {
    setSelectedPartner(partner);
    dispatch({ type: SET_SELECTED_PARTNER, payload: partner });
    const customersOfPartner = state.customers.filter((customer) => customer.partner === partner.id);
    setPartnerCustomers(customersOfPartner);
  };

  const handleClosePartner = () => {
    setSelectedPartner(null);
    setPartnerCustomers([]);
    dispatch({ type: CLEAR_SELECTED_PARTNER });
  };

  if (selectedPartner) {
    return (
      <Box sx={{ padding: 2 }}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5">{selectedPartner.name}</Typography>
              <IconButton onClick={handleClosePartner}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography>Email: {selectedPartner.email}</Typography>
              <Typography>Country: {selectedPartner.country}</Typography>
              <Typography>City: {selectedPartner.city}</Typography>
            </Box>
          </CardContent>
        </Card>
        <Typography variant="h6" sx={{ textAlign: 'center', marginTop: 4, marginBottom: 2 }}>
          Customers
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2, width: '100%' }} className="table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Alias</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Country</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partnerCustomers.map((customer) => (
                <TableRow
                  key={customer.id}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    },
                  }}
                >
                  <TableCell>{customer.name}</TableCell>
                  <TableCell>{customer.alias}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.country}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2, overflow: 'auto' }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <Box sx={{ marginBottom: 2, width: '100%' }}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
              <Grid item xs={12} sm={6} md={2.4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <OrangeButton variant="contained" onClick={() => setOpenAddPartner(true)}>
                  {BUTTON_LABELS.ADD_PARTNER}
                </OrangeButton>
              </Grid>
            </Grid>
          </Box>
          <TableContainer component={Paper} sx={{ marginTop: 2, width: '100%' }} className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>City</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.partners.map((partner) => (
                  <TableRow
                    key={partner.id}
                    onClick={() => handlePartnerClick(partner)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                    }}
                  >
                    <TableCell>{partner.name}</TableCell>
                    <TableCell>{partner.email}</TableCell>
                    <TableCell>{partner.country}</TableCell>
                    <TableCell>{partner.city}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {openAddPartner && (
            <AddPartnerForm
              onAddPartner={handleAddPartner}
              open={openAddPartner}
              onClose={() => setOpenAddPartner(false)}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Partners;
