// constants.js

export const API_ENDPOINTS = Object.freeze({
    FETCH_PARTNERS: '/api/partner/all',
    ADD_PARTNER: '/api/addpartner',
    FETCH_CUSTOMERS: '/api/customer/all',
    ADD_CUSTOMER: '/api/addcustomer',
    FETCH_USERS: '/api/user/all',
    FETCH_LICENSES: '/api/license/all',
    FETCH_PLUGINS: '/api/plugins',
    FETCH_GATEWAYS: '/api/gateways',
    ADD_LICENSE: '/api/addlicense',
  });
  
  export const PRIVILEGES = Object.freeze({
    SUPER: 'super',
    ADMIN: 'admin',
    USER: 'user',
  });
  
  export const COLORS = Object.freeze({
    BLUE: '#1f3f66',
    ORANGE: '#f18944',
    GREY: '#c0c0c0',
    BLUE_CIRCULAR_PROGRESS: '#2196f3',
  });
  
  export const BUTTON_LABELS = Object.freeze({
    RESET: 'Reset',
    CANCEL: 'Cancel',
    SUBMIT: 'Submit',
    DOWNLOAD: 'Download',
    ADD_PARTNER: '+ ADD PARTNER',
    ADD_CUSTOMER: '+ ADD CUSTOMER',
    ADD_LICENSE: 'Add License',
    LOGIN: 'Login',
    SIGN_IN: 'Sign In',
    LOGOUT: 'Logout',
  });
  
  export const ERROR_MESSAGES = Object.freeze({
    FETCH_DATA_FAILED: 'Failed to fetch data. Please check the console for more details.',
    ADD_CUSTOMER_FAILED: 'Failed to add customer. Please try again.',
    ADD_LICENSE_FAILED: 'Failed to add license. Please try again.',
    ADD_PARTNER_FAILED: 'Failed to add partner. Please try again.',
    INVALID_CREDENTIALS: 'Invalid username or password',
  });
  
  export const DOWNLOAD_FILE = '1gateway-2.28.1.tar.gz'; // Update as necessary
  