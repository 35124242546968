import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import './styles.css';
import { BUTTON_LABELS, ERROR_MESSAGES, COLORS } from './constants';

const LoginContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  height: '100vh', 
  backgroundColor: '#f9f9f9',
  padding: '20px',
  boxSizing: 'border-box', 
});

const FormContainer = styled(Box)({
  backgroundColor: '#ffffff',
  padding: '40px',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  maxWidth: '400px',
  width: '100%',
  textAlign: 'center',
});

const LoginLogo = styled('img')({
  width: '150px',
  marginBottom: '20px',
});

const StyledButton = styled(Button)({
  backgroundColor: COLORS.BLUE,
  color: '#ffffff',
  padding: '10px 20px',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: '#162049',
  },
});

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = login(username, password);
    if (result.success) {
      setError(null);
      navigate('/'); 
    } else {
      setError(result.message);
    }
  };

  return (
    <LoginContainer>
      <FormContainer>
        <LoginLogo src={`${process.env.PUBLIC_URL}/logo2.png`} alt="Logo" />
        <Typography variant="h5" component="h1" sx={{ mb: 2 }}>
          Sign in
        </Typography>
        <Typography variant="body2" sx={{ mb: 4 }}>
          Enter your 1Gateway account details.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 2 }}
          />
          {error && <Typography color="error">{ERROR_MESSAGES.INVALID_CREDENTIALS}</Typography>}
          <StyledButton variant="contained" type="submit">
            {BUTTON_LABELS.SIGN_IN}
          </StyledButton>
        </Box>
      </FormContainer>
    </LoginContainer>
  );
};

export default Login;
