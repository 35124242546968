import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography, Box, FormControl, InputLabel, Select, MenuItem, TextField, Grid, Button
} from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import AddLicenseForm from './AddLicenseForm';
import { useGlobalState } from './GlobalState';
import './styles.css';
import { API_ENDPOINTS, ERROR_MESSAGES, COLORS, BUTTON_LABELS } from './constants';
import { SET_LICENSES, SET_CUSTOMERS } from './actionTypes';

const OrangeButton = styled(Button)({
  backgroundColor: COLORS.ORANGE,
  '&:hover': { backgroundColor: '#d9783a' },
  '&.Mui-disabled': { backgroundColor: 'lightgrey' },
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  color: 'black',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
});

const Licenses = () => {
  const { state, dispatch } = useGlobalState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterPluginKey, setFilterPluginKey] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterCustomer, setFilterCustomer] = useState('');
  const [openAddLicense, setOpenAddLicense] = useState(false); // State to control Add License form dialog

  const [typeOptions, setTypeOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [filteredLicenses, setFilteredLicenses] = useState([]);

  const fetchLicensesAndCustomers = async () => {
    try {
      const [licenseResponse, customerResponse] = await Promise.all([
        axios.get(`https://pluginrepo.north.1bonding.com/license/all`, { headers: { 'Authorization': `Basic cGx1Z2luOjFCMG5kIW5n` } }),
        axios.get(`https://pluginrepo.north.1bonding.com/customer/all`, { headers: { 'Authorization': `Basic cGx1Z2luOjFCMG5kIW5n` } })
      ]);

      const licensesWithDefaults = licenseResponse.data.map(license => ({
        ...license,
        type: license.type || 'Unknown', // Ensure 'type' property is always present
      }));

      dispatch({ type: SET_LICENSES, payload: licensesWithDefaults });
      dispatch({ type: SET_CUSTOMERS, payload: customerResponse.data });
      setLoading(false);
    } catch (err) {
      console.error(ERROR_MESSAGES.FETCH_DATA_FAILED, err);
      setError(ERROR_MESSAGES.FETCH_DATA_FAILED);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLicensesAndCustomers();
  }, [dispatch]);

  useEffect(() => {
    const filterLicenses = (excludeFilter = '') => {
      return state.licenses.filter(license => {
        const expiryDate = new Date(license.expiry);
        const currentDate = new Date();
        return (
          expiryDate > currentDate &&
          (excludeFilter !== 'pluginKey' ? filterPluginKey ? license.pluginkey?.includes(filterPluginKey) : true : true) &&
          (excludeFilter !== 'type' ? filterType ? license.type === filterType : true : true) &&
          (excludeFilter !== 'customer' ? filterCustomer ? license.customer === filterCustomer : true : true)
        );
      });
    };

    const filteredLicenses = filterLicenses();
    setFilteredLicenses(filteredLicenses);
    setTypeOptions([...new Set(filterLicenses('type').map(license => license.type))]);
    setCustomerOptions([...new Set(filterLicenses('customer').map(license => license.customer))]);

  }, [filterPluginKey, filterType, filterCustomer, state.licenses]);

  const handleAddLicense = async (newLicense) => {
    try {
      await axios.post(`https://pluginrepo.north.1bonding.com/license?customer=1`, [newLicense], {
        headers: {
          'Content-Type': 'application/json',
          Authorization : 'Basic cGx1Z2luOjFCMG5kIW5n'
        }
      });
      fetchLicensesAndCustomers(); // Refresh the data after adding a new license
      setOpenAddLicense(false); // Close the Add License dialog
    } catch (error) {
      console.error(ERROR_MESSAGES.ADD_LICENSE_FAILED, error.response ? error.response.data : error.message);
      setError(ERROR_MESSAGES.ADD_LICENSE_FAILED);
    }
  };

  const calculateDaysToExpiry = (expiryDate) => {
    const currentDate = new Date();
    const expiry = new Date(expiryDate);
    const timeDiff = expiry - currentDate;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  };

  return (
    <Box sx={{ padding: 2, overflow: 'auto' }}>
      <Box sx={{ marginBottom: 2, width: '100%' }}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={6} md={2.4}>
            <TextField
              variant="outlined"
              label="Filter by Plugin Key"
              value={filterPluginKey}
              onChange={(e) => setFilterPluginKey(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Filter by Type</InputLabel>
              <Select
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
                label="Filter by Type"
              >
                <MenuItem value=""><em>All</em></MenuItem>
                {typeOptions.map(type => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Filter by Customer</InputLabel>
              <Select
                value={filterCustomer}
                onChange={(e) => setFilterCustomer(e.target.value)}
                label="Filter by Customer"
              >
                <MenuItem value=""><em>All</em></MenuItem>
                {customerOptions.map(customerId => {
                  const customer = state.customers.find(c => c.id === customerId);
                  return (
                    <MenuItem key={customer?.id} value={customer?.id}>
                      {customer?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2.4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <OrangeButton
              variant="contained"
              onClick={() => setOpenAddLicense(true)}
              startIcon={<AddIcon sx={{ color: 'black' }} />}
            >
              {BUTTON_LABELS.ADD_LICENSE}
            </OrangeButton>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 2, width: '100%' }} className="table-container">
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress sx={{ color: COLORS.BLUE_CIRCULAR_PROGRESS }} />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Plugin Key</TableCell>
                <TableCell style={{ width: '5%' }}>Expiry Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell style={{ width: '12%' }}>Days to Expiry</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>Customer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLicenses.map((license) => (
                <TableRow key={license.id}>
                  <TableCell>{license.pluginkey}</TableCell>
                  <TableCell style={{ width: '20%' }}>{license.expiry}</TableCell>
                  <TableCell>{license.type}</TableCell>
                  <TableCell style={{ width: '10%' }}>{calculateDaysToExpiry(license.expiry)}</TableCell>
                  <TableCell>{license.comment}</TableCell>
                  <TableCell>{state.customers.find(customer => customer.id === license.customer)?.name || ''}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {/* AddLicenseForm Dialog */}
      {openAddLicense && (
        <AddLicenseForm
          customers={state.customers}
          onAddLicense={handleAddLicense}
          open={openAddLicense}
          onClose={() => setOpenAddLicense(false)}
        />
      )}
    </Box>
  );
};

export default Licenses;
