import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';
import { useForm } from './hooks/useForm';
import { COLORS, BUTTON_LABELS, ERROR_MESSAGES } from './constants';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';

const AddLicenseForm = ({ customers, onAddLicense, open, onClose }) => {
  const [formValues, handleChange, resetForm] = useForm({
    customer: '',
    expiry: '',
    type: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    setIsSubmitDisabled(!formValues.customer || !formValues.expiry || !formValues.type);
  }, [formValues.customer, formValues.expiry, formValues.type]);

  const handleSubmit = async () => {
    setLoading(true);
    const newLicense = {
      customer: Number(formValues.customer),
      expiry: formValues.expiry,
      type: formValues.type,
    };

    try {
      await onAddLicense(newLicense);
      setLoading(false);
      onClose();
    } catch (err) {
      setError(ERROR_MESSAGES.ADD_LICENSE_FAILED);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add a New License</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="customer"
          name="customer"
          label="Customer"
          select
          fullWidth
          value={formValues.customer}
          onChange={handleChange}
          required
        >
          {customers.map((customer) => (
            <MenuItem key={customer.id} value={customer.id}>
              {customer.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          name="expiry"
          label="Expiry Date"
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={formValues.expiry}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          name="type"
          label="Type"
          fullWidth
          value={formValues.type}
          onChange={handleChange}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={resetForm}
          style={{ color: 'white', backgroundColor: COLORS.BLUE }}
        >
          {BUTTON_LABELS.RESET}
        </Button>
        <Button
          onClick={onClose}
          style={{ color: 'white', backgroundColor: COLORS.ORANGE }}
        >
          {BUTTON_LABELS.CANCEL}
        </Button>
        <Button
          onClick={handleSubmit}
          style={{
            color: 'white',
            backgroundColor: isSubmitDisabled || loading ? COLORS.GREY : COLORS.BLUE,
          }}
          variant="contained"
          disabled={isSubmitDisabled || loading}
        >
          {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : BUTTON_LABELS.SUBMIT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLicenseForm;
