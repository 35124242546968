import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS, ERROR_MESSAGES } from './constants';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`https://pluginrepo.north.1bonding.com/users`, {
          headers: {
            Authorization: 'Basic YWRtaW46MUIwbmQhbmc='}});
        const staticUsers = [
          {
            id: 999,
            name: 'Static User',
            email: 'user@domain.com',
            username: 'user',
            password: 'user',
            privilege: 'user',
          },
          {
            id: 999,
            name: 'Static User2',
            email: 'user@domain.com',
            username: 'admin',
            password: 'admin',
            privilege: 'admin',
          },
          {
            id: 999,
            name: 'Static User3',
            email: 'user@domain.com',
            username: 'super',
            password: 'super',
            privilege: 'super',
          },
        ];
        setUsers([...response.data, ...staticUsers]);
      } catch (error) {
        console.error(ERROR_MESSAGES.FETCH_DATA_FAILED, error);
      }
    };

    fetchUsers();
  }, []);

  const login = (username, password) => {
    const foundUser = users.find((u) => u.username === username && u.password === password);
    if (foundUser) {
      setUser(foundUser);
      navigate('/');
      return { success: true };
    }
    return { success: false, message: ERROR_MESSAGES.INVALID_CREDENTIALS };
  };

  const logout = () => {
    setUser(null);
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
