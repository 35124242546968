import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/system'; // Import styled here
import { OrangeButton } from './components/StyledButton'; // Import OrangeButton
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useGlobalState } from './GlobalState';
import './styles.css';
import { SET_PLUGINS } from './actionTypes';

const BlueCircularProgress = styled(CircularProgress)({
  color: '#2196f3', // Blue color
});

const compareVersions = (a, b) => {
  const numA = a.split('.').map((part) => part.padStart(3, '0')).join('');
  const numB = b.split('.').map((part) => part.padStart(3, '0')).join('');
  return numB.localeCompare(numA); // For descending order
};

const PluginList = () => {
  const { state, dispatch } = useGlobalState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedVersion, setSelectedVersion] = useState('');
  const [gatewayVersions, setGatewayVersions] = useState([]);
  const [plugins, setPlugins] = useState([]);
  const [filteredPlugins, setFilteredPlugins] = useState([]);
  const [loadingPlugins, setLoadingPlugins] = useState(false);
  const [selectedPlugins, setSelectedPlugins] = useState([]);

  const [versionOptions, setVersionOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    const fetchPlugins = async () => {
      setLoading(true); // Set initial loading to true
      try {
        const response = await axios.get(`https://pluginrepo.north.1bonding.com/plugins`, {
          headers: {
            Authorization: 'Basic cGx1Z2luOjFCMG5kIW5n'
        }});
        dispatch({ type: SET_PLUGINS, payload: response.data });
        setPlugins(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch plugins:', err);
        setError(
          'Failed to fetch plugins. Please check the console for more details.'
        );
        setLoading(false);
      }
    };

    if (state.plugins.length === 0) {
      fetchPlugins();
    } else {
      setPlugins(state.plugins);
      setLoading(false);
    }
  }, [dispatch, state.plugins.length]);

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const response = await axios.get(`https://pluginrepo.north.1bonding.com/gateways`, {
          headers: {
            Authorization: 'Basic cGx1Z2luOjFCMG5kIW5n'
        }});
        const sortedVersions = response.data.sort((a, b) =>
          compareVersions(a.name, b.name)
        );
        setGatewayVersions(sortedVersions);
      } catch (err) {
        console.error('Failed to fetch versions:', err);
      }
    };

    fetchVersions();
  }, []);

  useEffect(() => {
    const filterPlugins = (excludeFilter = '') => {
      return plugins.filter((plugin) => {
        return (
          (excludeFilter !== 'version'
            ? selectedVersion
              ? plugin.version === selectedVersion
              : true
            : true) &&
          (excludeFilter !== 'product'
            ? selectedProduct
              ? plugin.product.toLowerCase() === selectedProduct.toLowerCase()
              : true
            : true) &&
          (searchTerm
            ? plugin.name.toLowerCase().includes(searchTerm.toLowerCase())
            : true)
        );
      });
    };

    const filteredPlugins = filterPlugins();
    setFilteredPlugins(filteredPlugins);

    // Update version options by excluding the version filter in the condition
    setVersionOptions([
      ...new Set(
        filterPlugins('version').map((plugin) => plugin.version)
      ),
    ]);

    // Update product options by excluding the product filter in the condition
    setProductOptions([
      ...new Set(
        filterPlugins('product').map((plugin) => plugin.product)
      ),
    ]);
  }, [selectedVersion, selectedProduct, searchTerm, plugins]);

  const handleDownloadSelected = async () => {
    try {
      const zip = new JSZip();
      const downloadPromises = selectedPlugins.map(async (plugin) => {
        const filename = plugin.filename.endsWith('.zip')
          ? plugin.filename
          : `${plugin.filename}.zip`;
        const url = `/api/files/${filename}`;
        const response = await axios.get(`https://pluginrepo.north.1bonding.com/files/${filename}`, { 
          headers: {
            Authorization: 'Basic cGx1Z2luOjFCMG5kIW5n'
        }, responseType: 'blob' });
        zip.file(`${plugin.name}-${plugin.version}.zip`, response.data);
      });

      await Promise.all(downloadPromises);
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, 'selected_plugins.zip');
    } catch (error) {
      console.error('Error downloading selected plugins:', error);
    }
  };

  const handleSelectPlugin = (plugin) => {
    setSelectedPlugins((prevSelectedPlugins) => {
      if (prevSelectedPlugins.some((p) => p.key === plugin.key)) {
        return prevSelectedPlugins.filter((p) => p.key !== plugin.key);
      }
      return [...prevSelectedPlugins, plugin];
    });
  };

  const filteredList = filteredPlugins.filter(
    (plugin) =>
      plugin.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (!selectedProduct ||
        plugin.product.toLowerCase() === selectedProduct.toLowerCase())
  );

  // Remove duplicate entries
  const uniquePlugins = filteredList.reduce((acc, plugin) => {
    const key = `${plugin.name}-${plugin.version}-${plugin.plugintype}-${plugin.product}`;
    if (!acc.some((p) => p.key === key)) {
      acc.push({ ...plugin, key });
    }
    return acc;
  }, []);

  return (
    <Box sx={{ padding: 2, height: '100%' }}>
      <Box sx={{ marginBottom: 2, width: '100%' }}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              label="Search by name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{ startAdornment: <SearchIcon /> }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Version</InputLabel>
              <Select
                value={selectedVersion}
                onChange={(e) => setSelectedVersion(e.target.value)}
                label="Version"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {versionOptions.map((version) => (
                  <MenuItem key={version} value={version}>
                    {version}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Product</InputLabel>
              <Select
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
                label="Product"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {productOptions.map((product) => (
                  <MenuItem key={product} value={product}>
                    {product}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip
              title={
                selectedPlugins.map((plugin) => plugin.name).join(', ') ||
                'No plugins selected'
              }
            >
              <span>
                <OrangeButton
                  variant="contained"
                  startIcon={<DownloadIcon sx={{ color: 'black' }} />}
                  onClick={handleDownloadSelected}
                  disabled={selectedPlugins.length === 0}
                >
                  Download Selected
                </OrangeButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper} className="table-container">
        {loading || loadingPlugins ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <BlueCircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Product</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uniquePlugins.map((plugin) => (
                <TableRow key={plugin.key}>
                  <TableCell>{plugin.name}</TableCell>
                  <TableCell>{plugin.version}</TableCell>
                  <TableCell>{plugin.description}</TableCell>
                  <TableCell>{plugin.plugintype}</TableCell>
                  <TableCell>{plugin.product}</TableCell>
                  <TableCell align="right">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={selectedPlugins.some((p) => p.key === plugin.key)}
                      onChange={() => handleSelectPlugin(plugin)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
};

export default PluginList;
