import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalState } from '../GlobalState';

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dispatch } = useGlobalState(); // No need to access state anymore

  // Split the pathname into individual path segments
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Function to clear selected profile
  const clearSelectedProfile = (type) => {
    if (type === 'customers') {
      dispatch({ type: 'CLEAR_SELECTED_CUSTOMER' });
    } else if (type === 'partners') {
      dispatch({ type: 'CLEAR_SELECTED_PARTNER' });
    }
  };

  // Build the breadcrumbs items
  const breadcrumbs = [
    <Link
      key="home"
      color="inherit"
      onClick={() => {
        navigate('/');
        clearSelectedProfile(); // Clear selected profiles when navigating home
      }}
      underline="hover"
      sx={{ cursor: 'pointer' }}
    >
      Home
    </Link>,
    ...pathnames.map((value, index) => {
      const to = `/${pathnames.slice(0, index + 1).join('/')}`;
      const isLast = index === pathnames.length - 1;
      const isEntityListPage = value === 'customers' || value === 'partners';

      return (
        <Link
          key={to}
          color={isLast ? 'textPrimary' : 'inherit'}
          onClick={() => {
            if (isEntityListPage) {
              clearSelectedProfile(value); // Clear profile view when navigating back
            }
            navigate(to); // Navigate to the route
          }}
          underline={isLast ? 'none' : 'hover'}
        >
          {capitalizeFirstLetter(value)}
        </Link>
      );
    }),
  ]; // Removed the part that adds selectedEntityName

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      separator=" / "
      sx={{ marginTop: 2, marginLeft: 2 }}
    >
      {breadcrumbs}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
