import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  IconButton,
  Card,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/system';
import AddCustomerForm from './AddCustomerForm';
import { useGlobalState } from './GlobalState';
import SearchInput from './SearchInput';
import { useAuth } from './AuthContext';
import CloseIcon from '@mui/icons-material/Close'; 
import './styles.css';
import { API_ENDPOINTS, ERROR_MESSAGES, BUTTON_LABELS, COLORS } from './constants';
import {
  SET_PARTNERS,
  SET_CUSTOMERS,
  SET_SELECTED_CUSTOMER,
  CLEAR_SELECTED_CUSTOMER,
} from './actionTypes';

const OrangeButton = styled(Button)({
  backgroundColor: COLORS.ORANGE,
  '&:hover': { backgroundColor: '#d9783a' },
  '&.Mui-disabled': { backgroundColor: 'lightgrey' },
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  color: 'black',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
});

const Customers = () => {
  const { state, dispatch } = useGlobalState();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterPartner, setFilterPartner] = useState('');
  const [filterAlias, setFilterAlias] = useState('');
  const [filterCountry, setFilterCountry] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(state.selectedCustomer);
  const [loadingLicenses, setLoadingLicenses] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  const [partnerOptions, setPartnerOptions] = useState([]);
  const [aliasOptions, setAliasOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    const fetchCustomersAndPartners = async () => {
      setLoading(true);
      try {
        const [customerResponse, partnerResponse] = await Promise.all([
          axios.get(`https://pluginrepo.north.1bonding.com/customer/all`, { headers: { 'Authorization': `Basic cGx1Z2luOjFCMG5kIW5n` } }),
          axios.get(`https://pluginrepo.north.1bonding.com/partner/all`, { headers: { 'Authorization': `Basic cGx1Z2luOjFCMG5kIW5n` } }),
        ]);

        dispatch({ type: SET_CUSTOMERS, payload: customerResponse.data });
        dispatch({ type: SET_PARTNERS, payload: partnerResponse.data });
        setLoading(false);
      } catch (err) {
        console.error(ERROR_MESSAGES.FETCH_DATA_FAILED, err);
        setError(ERROR_MESSAGES.FETCH_DATA_FAILED);
        setLoading(false);
      }
    };

    if (state.customers.length === 0 || state.partners.length === 0) {
      fetchCustomersAndPartners();
    } else {
      setLoading(false);
    }

    return () => {
      dispatch({ type: CLEAR_SELECTED_CUSTOMER });
    };
  }, [dispatch, state.customers.length, state.partners.length]);

  const getPartnerName = (partnerId) => {
    const partner = state.partners.find((p) => p.id === partnerId);
    return partner ? partner.name : 'Unknown Partner';
  };

  useEffect(() => {
    const filterCustomers = () => {
      return state.customers.filter(customer => {
        return (
          (user.privilege === 'super' || customer.partner === user.partner_id) &&
          (filterPartner ? getPartnerName(customer.partner) === filterPartner : true) &&
          (filterAlias ? customer.alias === filterAlias : true) &&
          (filterCountry ? customer.country === filterCountry : true) &&
          (searchTerm ? customer.name.toLowerCase().includes(searchTerm.toLowerCase()) : true)
        );
      });
    };

    const filteredCustomers = filterCustomers();
    setFilteredCustomers(filteredCustomers);

    const uniquePartners = [...new Set(filteredCustomers.map(customer => getPartnerName(customer.partner)))];
    const uniqueAliases = [...new Set(filteredCustomers.map(customer => customer.alias))];
    const uniqueCountries = [...new Set(filteredCustomers.map(customer => customer.country))];

    setPartnerOptions(uniquePartners);
    setAliasOptions(uniqueAliases);
    setCountryOptions(uniqueCountries);

  }, [filterPartner, filterAlias, filterCountry, searchTerm, state.customers, user.privilege, user.partner_id]);

  const handleAddCustomer = async (newCustomer) => {
    try {
      const response = await axios.post(`https://pluginrepo.north.1bonding.com/customer`, newCustomer, {
        headers: {
          'Content-Type': 'application/json',
          Authorization : 'Basic cGx1Z2luOjFCMG5kIW5n'
        },
      });
      dispatch({
        type: SET_CUSTOMERS,
        payload: [...state.customers, response.data],
      });
    } catch (error) {
      console.error(ERROR_MESSAGES.ADD_CUSTOMER_FAILED, error);
      setError(ERROR_MESSAGES.ADD_CUSTOMER_FAILED);
    }
  };

  const handleCustomerClick = async (customer) => {
    setSelectedCustomer(customer);
    dispatch({ type: SET_SELECTED_CUSTOMER, payload: customer }); 
    setLoadingLicenses(true);

    try {
      const licenseResponse = await axios.get(`https://pluginrepo.north.1bonding.com/license/all`, { headers: { 'Authorization': `Basic cGx1Z2luOjFCMG5kIW5n` } });
      const currentDate = new Date();
      const filteredLicenses = licenseResponse.data.filter(
        (license) =>
          new Date(license.expiry) > currentDate && license.customer === customer.id
      );
      setLicenses(filteredLicenses);
      setLoadingLicenses(false);
    } catch (err) {
      console.error(ERROR_MESSAGES.FETCH_DATA_FAILED, err);
      setError(ERROR_MESSAGES.FETCH_DATA_FAILED);
      setLoadingLicenses(false);
    }
  };

  const handleCloseCustomer = () => {
    setSelectedCustomer(null);
    setLicenses([]);
    dispatch({ type: CLEAR_SELECTED_CUSTOMER }); 
  };

  if (selectedCustomer) {
    return (
      <Box sx={{ padding: 2 }}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5">{selectedCustomer.name}</Typography>
              <IconButton onClick={handleCloseCustomer}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography>Email: {selectedCustomer.email}</Typography>
              <Typography>Alias: {selectedCustomer.alias}</Typography>
              <Typography>Country: {selectedCustomer.country}</Typography>
              <Typography>Partner: {getPartnerName(selectedCustomer.partner)}</Typography>
            </Box>
          </CardContent>
        </Card>

        {loadingLicenses ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress />
          </Box>
        ) : licenses && licenses.length > 0 && (
          <>
            <Typography variant="h6" sx={{ textAlign: 'center', marginTop: 4, marginBottom: 2 }}>
              Licenses
            </Typography>

            <TableContainer component={Paper} sx={{ marginTop: 2, width: '100%' }} className="table-container">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Plugin Key</TableCell>
                    <TableCell>Expiry Date</TableCell>
                    <TableCell>Days to Expiry</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {licenses.map((license) => {
                    const expiryDate = new Date(license.expiry);
                    const currentDate = new Date();
                    const daysToExpiry = Math.ceil((expiryDate - currentDate) / (1000 * 60 * 60 * 24));
                    return (
                      <TableRow key={license.id}>
                        <TableCell>{license.pluginkey}</TableCell>
                        <TableCell>{license.expiry}</TableCell>
                        <TableCell>{daysToExpiry}</TableCell>
                        <TableCell>{license.type}</TableCell>
                        <TableCell>{license.comment}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2, overflow: 'auto' }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <Box sx={{ marginBottom: 2, width: '100%' }}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Filter by Alias</InputLabel>
                  <Select
                    value={filterAlias}
                    onChange={(e) => setFilterAlias(e.target.value)}
                    label="Filter by Alias"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {aliasOptions.map((alias) => (
                      <MenuItem key={alias} value={alias}>
                        {alias}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Filter by Country</InputLabel>
                  <Select
                    value={filterCountry}
                    onChange={(e) => setFilterCountry(e.target.value)}
                    label="Filter by Country"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {countryOptions.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {user.privilege === 'super' && (
                <Grid item xs={12} sm={6} md={2.4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Filter by Partner</InputLabel>
                    <Select
                      value={filterPartner}
                      onChange={(e) => setFilterPartner(e.target.value)}
                      label="Filter by Partner"
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {partnerOptions.map((partner) => (
                        <MenuItem key={partner} value={partner}>
                          {partner}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={2.4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <OrangeButton variant="contained" onClick={() => setOpenAddCustomer(true)}>
                  {BUTTON_LABELS.ADD_CUSTOMER}
                </OrangeButton>
              </Grid>
            </Grid>
          </Box>
          <TableContainer component={Paper} sx={{ marginTop: 2, width: '100%' }} className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Alias</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Partner</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCustomers.map((customer) => (
                  <TableRow
                    key={customer.id}
                    onClick={() => handleCustomerClick(customer)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                    }}
                  >
                    <TableCell>{customer.name}</TableCell>
                    <TableCell>{customer.alias}</TableCell>
                    <TableCell>{customer.country}</TableCell>
                    <TableCell>{getPartnerName(customer.partner)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {openAddCustomer && (
            <AddCustomerForm
              partners={state.partners}
              onAddCustomer={handleAddCustomer}
              open={openAddCustomer}
              onClose={() => setOpenAddCustomer(false)}
            />
          )}
        </>
      )}
    </Box>
  );
  
};

export default Customers;
