// actionTypes.js

export const SET_PARTNERS = 'SET_PARTNERS';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_LICENSES = 'SET_LICENSES';
export const SET_PLUGINS = 'SET_PLUGINS';
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER';
export const CLEAR_SELECTED_CUSTOMER = 'CLEAR_SELECTED_CUSTOMER';
export const SET_SELECTED_PARTNER = 'SET_SELECTED_PARTNER';
export const CLEAR_SELECTED_PARTNER = 'CLEAR_SELECTED_PARTNER';
