import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Button,
} from '@mui/material';
import { useForm } from './hooks/useForm';
import './styles.css';
import { COLORS, BUTTON_LABELS, PRIVILEGES } from './constants';

const AddCustomerForm = ({ partners, onAddCustomer, open, onClose }) => {
  const [formValues, handleChange, resetForm] = useForm({
    name: '',
    alias: '',
    partnerId: '',
    email: '',
    country: '',
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    setIsSubmitDisabled(!formValues.name || !formValues.partnerId);
  }, [formValues.name, formValues.partnerId]);

  const handleSubmit = () => {
    const selectedPartner = partners.find(
      (partner) => partner.id === formValues.partnerId
    );
    const newCustomer = {
      ...formValues,
      login: formValues.email,
      password: 'abc123',
      partner: formValues.partnerId,
      partnerName: selectedPartner ? selectedPartner.name : '',
      privilege: PRIVILEGES.USER,
    };
    onAddCustomer(newCustomer);
    onClose();
    resetForm();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add a New Customer</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="name"
          name="name"
          label="Name"
          fullWidth
          value={formValues.name}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          id="alias"
          name="alias"
          label="Alias"
          fullWidth
          value={formValues.alias}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="partnerId"
          name="partnerId"
          label="Partner"
          select
          fullWidth
          value={formValues.partnerId}
          onChange={handleChange}
          required
        >
          {partners.map((partner) => (
            <MenuItem key={partner.id} value={partner.id}>
              {partner.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          id="email"
          name="email"
          label="Email"
          type="email"
          fullWidth
          value={formValues.email}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="country"
          name="country"
          label="Country"
          fullWidth
          value={formValues.country}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={resetForm}
          style={{ color: 'white', backgroundColor: COLORS.BLUE }}
        >
          {BUTTON_LABELS.RESET}
        </Button>
        <Button
          onClick={onClose}
          style={{ color: 'white', backgroundColor: COLORS.ORANGE }}
        >
          {BUTTON_LABELS.CANCEL}
        </Button>
        <Button
          onClick={handleSubmit}
          style={{
            color: 'white',
            backgroundColor: isSubmitDisabled ? COLORS.GREY : COLORS.BLUE,
          }}
          variant="contained"
          disabled={isSubmitDisabled}
        >
          {BUTTON_LABELS.SUBMIT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomerForm;
