// components/StyledButton.js
import { styled } from '@mui/system';
import { Button } from '@mui/material';

// OrangeButton styled component
export const OrangeButton = styled(Button)({
  backgroundColor: '#f18944',
  '&:hover': { backgroundColor: '#d9783a' },
  '&.Mui-disabled': { backgroundColor: 'lightgrey' },
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  color: 'black',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
});

// StyledButton styled component
export const StyledButton = styled(Button)({
  backgroundColor: '#1F3F66',
  color: '#ffffff',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#162049',
  },
});

export default OrangeButton;
