// GlobalState.js

import React, { createContext, useReducer, useContext } from 'react';

// Create the context
const GlobalStateContext = createContext();

// Define the initial state
const initialState = {
  partners: [],
  customers: [],
  plugins: [],
  licenses: [],
  selectedCustomer: null,
  selectedPartner: null,
};

// Define the reducer to handle state updates
const globalStateReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PARTNERS':
      return { ...state, partners: action.payload };
    case 'SET_CUSTOMERS':
      return { ...state, customers: action.payload };
    case 'SET_PLUGINS':
      return { ...state, plugins: action.payload };
    case 'SET_LICENSES':
      return { ...state, licenses: action.payload };
    case 'SET_SELECTED_CUSTOMER':
      return { ...state, selectedCustomer: action.payload };
    case 'CLEAR_SELECTED_CUSTOMER':
      return { ...state, selectedCustomer: null };
    case 'SET_SELECTED_PARTNER':
      return { ...state, selectedPartner: action.payload };
    case 'CLEAR_SELECTED_PARTNER':
      return { ...state, selectedPartner: null };
    case 'UPDATE_CUSTOMER':
      return {
        ...state,
        customers: state.customers.map((customer) =>
          customer.id === action.payload.id ? action.payload : customer
        ),
      };
    case 'DELETE_CUSTOMER':
      return {
        ...state,
        customers: state.customers.filter((customer) => customer.id !== action.payload),
      };
    case 'UPDATE_PARTNER':
      return {
        ...state,
        partners: state.partners.map((partner) =>
          partner.id === action.payload.id ? action.payload : partner
        ),
      };
    case 'DELETE_PARTNER':
      return {
        ...state,
        partners: state.partners.filter((partner) => partner.id !== action.payload),
      };
    default:
      return state;
  }
};

// Define the provider component
export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalStateReducer, initialState);

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Custom hook to use the global state
export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};
