// App.js
import React, { useState } from 'react';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useLocation,
} from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import { useAuth, AuthProvider } from './AuthContext';
import PluginList from './PluginList';
import Home from './Home';
import Login from './Login';
import Partners from './Partners';
import Customers from './Customers';
import CustomerLicenses from './CustomerLicenses';
import Licenses from './Licenses';
import './styles.css'; // Import CSS file for styling
import Breadcrumbs from './components/Breadcrumbs'; // Import Breadcrumbs

const StyledButton = styled(Button)({
  backgroundColor: '#f18944',
  color: '#000000',
  '&:hover': {
    backgroundColor: '#d9783a',
  },
  borderRadius: '50px',
  padding: '10px 20px',
  fontWeight: 'bold',
});

const StyledListItem = styled(ListItem)(({ selected }) => ({
  backgroundColor: selected ? '#a0a0a0' : 'inherit',
  '&:hover': {
    backgroundColor: selected ? '#a0a0a0' : '#d3d3d3',
  },
  '& .MuiListItemText-root': {
    color: 'black',
  },
}));

const ProtectedRoute = ({ children, role }) => {
  const { user } = useAuth();
  if (
    !user ||
    (role &&
      user.privilege !== role &&
      !(user.privilege === 'super' && !user.partnerId))
  ) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const App = () => {
  const { user, logout } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{ width: 250, height: '100%' }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        <img
          src={`${process.env.PUBLIC_URL}/logo2.png`}
          alt="Logo"
          style={{ width: '150px' }}
        />
      </Box>
      <Divider />
      <List>
        <StyledListItem
          button
          component={Link}
          to="/"
          selected={location.pathname === '/'}
        >
          <ListItemText primary="Home" />
        </StyledListItem>
        <Divider />
        {user && (
          <>
            {user.privilege === 'super' && !user.partnerId && (
              <>
                <StyledListItem
                  button
                  component={Link}
                  to="/partners"
                  selected={location.pathname === '/partners'}
                >
                  <ListItemText primary="Partners" />
                </StyledListItem>
                <Divider />
                <StyledListItem
                  button
                  component={Link}
                  to="/customers"
                  selected={location.pathname === '/customers'}
                >
                  <ListItemText primary="Customers" />
                </StyledListItem>
                <Divider />
                <StyledListItem
                  button
                  component={Link}
                  to="/plugins"
                  selected={location.pathname === '/plugins'}
                >
                  <ListItemText primary="Plugins" />
                </StyledListItem>
                <Divider />
              </>
            )}
            {(user.privilege === 'admin' ||
              (user.privilege === 'super' && user.partnerId)) && (
              <>
                <StyledListItem
                  button
                  component={Link}
                  to="/customers"
                  selected={location.pathname === '/customers'}
                >
                  <ListItemText primary="Customers" />
                </StyledListItem>
                <Divider />
                <StyledListItem
                  button
                  component={Link}
                  to="/plugins"
                  selected={location.pathname === '/plugins'}
                >
                  <ListItemText primary="Plugins" />
                </StyledListItem>
                <Divider />
              </>
            )}
            {user.privilege === 'user' && (
              <>
                <StyledListItem
                  button
                  component={Link}
                  to="/plugins"
                  selected={location.pathname === '/plugins'}
                >
                  <ListItemText primary="Plugins" />
                </StyledListItem>
                <Divider />
                <StyledListItem
                  button
                  component={Link}
                  to="/licenses"
                  selected={location.pathname === '/licenses'}
                >
                  <ListItemText primary="Licenses" />
                </StyledListItem>
                <Divider />
              </>
            )}
            <StyledListItem button onClick={logout}>
              <ListItemText primary="Logout" />
            </StyledListItem>
          </>
        )}
        {!user && (
          <StyledListItem
            button
            component={Link}
            to="/login"
            selected={location.pathname === '/login'}
          >
            <ListItemText primary="Login" />
          </StyledListItem>
        )}
      </List>
    </Box>
  );

  return (
    <>
      {user && (
        <AppBar position="static" className="navbar">
          <Toolbar
            className="toolbar"
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              PaperProps={{ style: { height: '100vh' } }}
            >
              {drawerContent}
            </Drawer>
            <Link
              to="/"
              style={{
                textDecoration: 'none',
                color: 'inherit',
                marginTop: 5,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/logo.png`}
                alt="Logo"
                className="logo"
                style={{ height: '35px', marginLeft: '15px' }}
              />
            </Link>
            {/* App Title Removed */}
            <Box
              className="nav-links"
              sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}
            >
              {user ? (
                <StyledButton onClick={logout}>Logout</StyledButton>
              ) : (
                <Link to="/login" className="nav-link">
                  Login
                </Link>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Container>
        {/* Conditionally render Breadcrumbs only if the user is authenticated */}
        {user && <Breadcrumbs />}
        <Routes>
          <Route path="/" element={user ? <Home /> : <Login />} />
          <Route
            path="/plugins"
            element={
              <ProtectedRoute>
                <PluginList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/licenses"
            element={
              <ProtectedRoute role="user">
                <Licenses />
              </ProtectedRoute>
            }
          />
          <Route
            path="/partners"
            element={
              <ProtectedRoute role="super">
                <Partners />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <ProtectedRoute>
                <Customers />
              </ProtectedRoute>
            }
          />
          {/* This route is unnecessary since we don't want to navigate directly to a customer ID */}
          {/* <Route path="/customers/:customerId/licenses" element={<CustomerLicenses />} /> */}
          <Route path="/login" element={<Login />} />
        </Routes>
      </Container>
    </>
  );
};

const WrappedApp = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default WrappedApp;
